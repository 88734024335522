import React from "react";
import MainNav from "./MainNav";
import * as styles from "./PageHeader.module.scss";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import LanguageSelect from "./LanguageSelect";

export type PageHeaderProps = {
  context: { id: string; originalId: string; locale: string };
};

const PageHeader: React.FC<PageHeaderProps> = (props) => {
  //console.log('Rendering PageHeader', props)
  const [atTop, setAtTop] = React.useState<boolean>(true);

  React.useEffect(() => {
    function onScroll(_: Event) {
      const scrollTop = !(
        document.body.scrollTop || document.documentElement.scrollTop
      );
      if (scrollTop !== atTop) setAtTop(scrollTop);
    }

    document.addEventListener("scroll", onScroll);
    return () => {
      document.removeEventListener("scroll", onScroll);
    };
  });

  return (
    <header className={styles.header + " " + styles[atTop ? "atTop" : ""]}>
      <div className={styles.container}>
        <Link
          className={styles.logoContainer}
          to={props.context.locale === "fi" ? "/" : "/" + props.context.locale}
        >
          <StaticImage
            width={120}
            className={styles.logo}
            src="../images/PargasHavis_3.png"
            alt="Pargas Havis logo"
          />
        </Link>
        <div className={styles.headerRight}>
          <MainNav context={props.context} />
          <LanguageSelect context={props.context} />
          {/* <div className={styles.search}>
            <button className={styles.searchButton}>
              <IoMdSearch />
            </button>
          </div> */}
        </div>
      </div>
    </header>
  );
};

export default PageHeader;
