import { graphql, useStaticQuery } from "gatsby";

export type PagePath = {
  path: string;
  pageContext: {
    id: string;
    locale: string;
    originalId: string;
  };
};

type RoutePathMapData = {
  allSitePage: {
    nodes: Array<PagePath>;
  };
};

/**
 * Palauttaa Dato CMS routeId:tä vastaavan, routesta tehdyn sivun URL polun
 */
const usePagePath = (routeId: string, locale?: string) => {
  const data = useStaticQuery<RoutePathMapData>(
    graphql`
      query DatoCmsPagePathMap {
        allSitePage(
          filter: {
            componentChunkName: {
              eq: "component---src-page-templates-dato-page-template-tsx"
            }
          }
        ) {
          nodes {
            path
            pageContext
          }
        }
      }
    `
  );

  const page = data.allSitePage.nodes.find(
    (page) =>
      page.pageContext.id === routeId ||
      (page.pageContext.originalId === routeId &&
        page.pageContext.locale === locale)
  );

  return page?.path || "";
};

export default usePagePath;
