import { graphql } from "gatsby";
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image";
import { StructuredTextDocument } from "react-datocms";
import React from "react";
import * as styles from "./DatoIconList.module.scss";
import DatoStructuredTextBlock from "../DatoStructuredTextBlock";
import SubHeading from "../../SubHeading";
import { slugify } from "../../../utils";

export type DatoIconProps = {
  title?: string;
  image: {
    gatsbyImageData: IGatsbyImageData;
    alt: string;
  };
  text: StructuredTextDocument;
};

const DatoIcon: React.FC<DatoIconProps> = (props) => {
  //console.log("Rendering DatoIcon", props);
  return (
    <div className={styles.iconContainer}>
      <GatsbyImage
        className={styles.icon}
        image={props.image.gatsbyImageData}
        alt={props.image.alt || ""}
        objectFit="contain"
      />
      {props.title && <h3 className={styles.iconTitle}>{props.title}</h3>}
      <DatoStructuredTextBlock data={props.text} />
    </div>
  );
};

export type DatoIconListProps = {
  title?: string;
  icons: DatoIconProps[];
};

const DatoIconList: React.FC<DatoIconListProps> = (props) => {
  //console.log("Rendering DatoIconList", props);
  return (
    <div className="layout-normal" id={slugify(props.title)}>
      {props.title && <SubHeading>{props.title}</SubHeading>}
      <div className={styles.icons}>
        {props.icons?.map((icon, i) => {
          return <DatoIcon key={i} {...icon} />;
        })}
      </div>
    </div>
  );
};

export default DatoIconList;

export const query = graphql`
  fragment DatoIcon on DatoCmsIcon {
    id: originalId # Korjaus daton bugiin, jos käytetään structured textissä
    title
    image {
      alt
      gatsbyImageData(width: 100, height: 100)
    }
    text {
      value
      links {
        __typename
        ... on DatoCmsPage {
          id: originalId
          targetId: id # linkin kohde
          label: title # button label
        }
      }
    }
  }
  fragment DatoIconList on DatoCmsIconList {
    id: originalId # Korjaus daton bugiin, jos käytetään structured textissä
    title
    icons {
      ...DatoIcon
    }
  }
`;
