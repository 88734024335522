import React from "react";
import { StructuredText } from "react-datocms";
import DatoBlock, { DatoBlockProps } from "./DatoBlock";
import DatoInlineRecord, { DatoInlineRecordProps } from "./DatoInlineRecord";
import DatoLinkToRecord, { DatoLinkToRecordProps } from "./DatoLinkToRecord";
import { shiftHeadings } from "./utils";
//import * as styles from "./DatoStructuredTextBlock.module.scss"

export const isEmpty = (data: any) => {
  const nodes = data.value?.document?.children;
  if (
    !nodes ||
    (nodes.length === 1 &&
      nodes[0].children?.length === 1 &&
      !nodes[0].children[0].value &&
      !nodes[0].children[0].children)
  ) {
    return true;
  }

  return false;
};

export type DatoStructuredTextBlockProps = {
  data: any;
  shiftHeadings?: number;
};

const DatoStructuredTextBlock: React.FC<DatoStructuredTextBlockProps> = (
  props
) => {
  // console.debug("Rendering DatoStructuredTextBlock", props)

  if (!props.data?.value) return null;

  // Siivousta: Tyhjä sisältö Datossa => ei renderoida tyhjää <p></p>
  if (isEmpty(props.data)) {
    return null;
  }

  const shiftedData = shiftHeadings(props.data, props.shiftHeadings || 1);

  return (
    <StructuredText
      data={shiftedData}
      renderLinkToRecord={(ctx) => {
        return (
          <DatoLinkToRecord
            {...(ctx.record as unknown as DatoLinkToRecordProps)}
            newWindow={ctx.transformedMeta?.target === "_blank"}
          >
            {ctx.children}
          </DatoLinkToRecord>
        );
      }}
      renderInlineRecord={({ record }) =>
        record && (
          <DatoInlineRecord {...(record as unknown as DatoInlineRecordProps)} />
        )
      }
      renderBlock={({ record }) =>
        record && <DatoBlock {...(record as DatoBlockProps)} />
      }
    />
  );
};

export default DatoStructuredTextBlock;
