import { graphql } from "gatsby";
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image";
import React from "react";
import Carousel from "../../Carousel";
import DatoStructuredTextBlock from "../DatoStructuredTextBlock";
import { StructuredTextDocument } from "react-datocms";
import * as styles from "./DatoImagesAndText.module.scss";
import { slugify } from "../../../utils";

export type DatoImagesAndTextProps = {
  title?: string;
  reverseOrder: boolean;
  backgroundGradient: boolean;
  content: StructuredTextDocument;
  images: Array<{
    gatsbyImageData: IGatsbyImageData;
    alt?: string;
  }>;
};

const DatoImagesAndText: React.FC<DatoImagesAndTextProps> = (props) => {
  //console.log('Rendering DatoImagesAndText', props)
  return (
    <div
      className={props.backgroundGradient ? styles.gradient : undefined}
      id={slugify(props.title)}
    >
      <div
        className={[
          styles.container,
          props.reverseOrder && styles.reverse,
          "layout-normal",
        ]
          .filter(Boolean)
          .join(" ")}
      >
        <div className={styles.images}>
          {props.images.length > 1 ? (
            <Carousel slideClass={styles.slide}>
              {props.images?.map((img, i) => {
                return (
                  <GatsbyImage
                    key={i}
                    className={styles.image}
                    image={img.gatsbyImageData}
                    alt={img.alt || ""}
                  />
                );
              })}
            </Carousel>
          ) : (
            <GatsbyImage
              className={styles.image}
              image={props.images[0].gatsbyImageData}
              alt={props.images[0].alt || ""}
              objectFit="contain"
            />
          )}
        </div>

        <div className={styles.content}>
          {props.title && <h2 className={styles.title}>{props.title}</h2>}
          <DatoStructuredTextBlock data={props.content} shiftHeadings={2} />
        </div>
      </div>
    </div>
  );
};

export default DatoImagesAndText;

export const query = graphql`
  fragment DatoImagesAndText on DatoCmsImagesAndText {
    id: originalId # Korjaus daton bugiin, jos käytetään structured textissä
    title
    reverseOrder
    backgroundGradient
    content {
      value
      links {
        __typename
        ... on DatoCmsPage {
          id: originalId
          targetId: id # linkin kohde
          label: title # button label
        }
      }
    }
    images {
      gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
      alt
    }
  }
`;
