import { Link } from "gatsby";
import React from "react";
import usePagePath from "../../hooks/usePagePath";
import Button from "../Button";
export type DatoInlineRecordProps = { __typename: string; targetId: string } & {
  [prop: string]: any;
};
/**
 * Mappaa structured text inline recordit React komponentteihin
 */
const DatoInlineRecord = (props: DatoInlineRecordProps) => {
  const { __typename, ...rest } = props;

  switch (__typename) {
    case "DatoCmsPage":
      return (
        <Link to={usePagePath(rest.targetId)}>
          <Button blue tabIndex={-1}>
            {rest.label}
          </Button>
        </Link>
      );

    default:
      console.warn("Unhandled InlineRecord", __typename);
      return null; //<span>&lt;Unhandled InlineRecord: {__typename}&gt;</span> //null
  }
};

export default DatoInlineRecord;
