import { graphql } from "gatsby";
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image";
import { StructuredTextDocument } from "react-datocms";
import React from "react";
import DatoStructuredTextBlock from "../DatoStructuredTextBlock";
import * as styles from "./DatoHero.module.scss";
import { slugify } from "../../../utils";

export type DatoHeroProps = {
  title: string;
  text?: StructuredTextDocument;
  background: {
    gatsbyImageData: IGatsbyImageData;
    alt: string;
  };
  image?: {
    gatsbyImageData: IGatsbyImageData;
    alt: string;
  };
};

const DatoHero: React.FC<DatoHeroProps> = (props) => {
  //console.log('Rendering DatoHero', props)
  return (
    <div className={styles.container} id={slugify(props.title)}>
      <GatsbyImage
        className={styles.bgImage}
        image={props.background.gatsbyImageData}
        alt={props.background.alt || ""}
      />
      <div className={styles.content}>
        {props.image && (
          <GatsbyImage
            image={props.image.gatsbyImageData}
            alt={props.image.alt || ""}
          />
        )}
        <h1>{props.title}</h1>
        <DatoStructuredTextBlock data={props.text} />
      </div>
    </div>
  );
};

export default DatoHero;

export const query = graphql`
  fragment DatoHero on DatoCmsHero {
    id: originalId # Korjaus daton bugiin, jos käytetään structured textissä
    title
    text {
      value
    }
    background {
      gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
      alt
    }
    image {
      gatsbyImageData(placeholder: BLURRED, width: 500)
      alt
    }
  }
`;
