import { graphql } from "gatsby";
import React from "react";
import { StructuredTextDocument } from "react-datocms";
import { slugify } from "../../../utils";
import Accordion from "../../Accordion";
import DatoStructuredTextBlock from "../DatoStructuredTextBlock";
import * as styles from "./DatoAccordionList.module.scss";

export type DatoAccordionProps = {
  id: string;
  title: string;
  content: StructuredTextDocument;
};

const DatoAccordion: React.FC<DatoAccordionProps> = (props) => {
  //console.log('Rendering DatoAccordion', props)
  return (
    <Accordion title={props.title}>
      <DatoStructuredTextBlock data={props.content} shiftHeadings={2} />
    </Accordion>
  );
};

export type DatoAccordionListProps = {
  title?: string;
  accordions: Array<DatoAccordionProps>;
};

const DatoAccordionList: React.FC<DatoAccordionListProps> = (props) => {
  //console.log('Rendering DatoAccordionList', props)
  const col1 = props.accordions.slice(0, props.accordions.length / 2.01 + 1);
  const col2 = props.accordions.slice(props.accordions.length / 2.01 + 1);

  return (
    <>
      <div
        className={styles.container + " layout-normal"}
        id={slugify(props.title)}
      >
        {props.title && <h2>{props.title}</h2>}
        <div className={styles.accordions}>
          <div className={styles.column}>
            {col1.map((item) => (
              <DatoAccordion key={item.id} {...item} />
            ))}
          </div>

          <div className={styles.column}>
            {col2.map((item) => (
              <DatoAccordion key={item.id} {...item} />
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default DatoAccordionList;

export const query = graphql`
  fragment DatoAccordion on DatoCmsAccordion {
    id: originalId # Korjaus daton bugiin, jos käytetään structured textissä
    title
    content {
      value
      blocks {
        __typename
        ...DatoMediaBlock
      }
      links {
        __typename
        ... on DatoCmsPage {
          id: originalId
          targetId: id # linkin kohde
          label: title # button label
        }
      }
    }
  }

  fragment DatoAccordionList on DatoCmsAccordionList {
    id: originalId # Korjaus daton bugiin, jos käytetään structured textissä
    title
    accordions {
      ...DatoAccordion
    }
  }
`;
