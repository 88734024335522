import React from "react";
//import * as styles from "./CookieDeclaration.module.scss"

export type CookieDeclarationProps = { cookieBotId: string; locale?: string };

const CookieDeclaration: React.FC<CookieDeclarationProps> = (props) => {
  //console.log('Rendering CookieDeclaration', props)
  React.useEffect(() => {
    const scriptTag = document.createElement("script");
    scriptTag.src = `https://consent.cookiebot.com/${props.cookieBotId}/cd.js`;
    scriptTag.type = "text/javascript";
    scriptTag.async = true;
    scriptTag.dataset.culture = props.locale;

    const wrapper = document.getElementById("cookieDeclarationWrapper");
    if (wrapper) {
      wrapper.appendChild(scriptTag);
    }
  }, []);
  return <div id="cookieDeclarationWrapper"></div>;
};

export default CookieDeclaration;
