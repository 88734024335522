import { Link } from "gatsby";
import React, { ReactNode } from "react";
import usePagePath from "../../hooks/usePagePath";

type DatoLinkToRecordData = { __typename: string } & {
  [prop: string]: unknown;
};

export type DatoLinkToRecordProps = {
  children?: ReactNode;
  targetId: string /** Linkin kohteen id */;
  newWindow?: boolean;
} & DatoLinkToRecordData;

/**
 * Mappaa structured text linkit React komponentteihin
 */
const DatoLinkToRecord = (props: DatoLinkToRecordProps) => {
  const { __typename, ...rest } = props;

  switch (__typename) {
    case "DatoCmsPage":
      return <Link to={usePagePath(rest.targetId)}>{rest.children}</Link>;

    default:
      console.warn(`Unknown type "${__typename}". Rendering skipped`);
      return null; //<span>&lt;Unhandled link: {__typename}&gt;</span>;
  }
};

export default DatoLinkToRecord;
