import { graphql } from "gatsby";
import React from "react";
import CookieDeclaration from "../../CookieDeclaration";
//import * as styles from "./DatoCookieDeclaration.module.scss"

export type DatoCookieDeclarationProps = {
  title?: string;
  locale: string /** Tulee DatoPageTemplatelta */;
};

const DatoCookieDeclaration: React.FC<DatoCookieDeclarationProps> = (props) => {
  //console.log("Rendering DatoCookieDeclaration", props);
  return (
    <div className="layout-normal">
      {props.title && <h2>{props.title}</h2>}
      <CookieDeclaration
        cookieBotId="5eb1d44e-b82e-418f-baaa-426bc2ff1432" // TODO, tämä jostain conffista, ei kovakoodausta tässä kohdassa
        locale={props.locale}
      />
    </div>
  );
};

export default DatoCookieDeclaration;

export const query = graphql`
  fragment DatoCookieDeclaration on DatoCmsCookieDeclaration {
    id: originalId # Korjaus daton bugiin, jos käytetään structured textissä
    title
  }
`;
