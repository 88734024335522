import { graphql, PageProps } from "gatsby";
import React from "react";
import { HelmetDatoCms } from "gatsby-source-datocms";
import PageHeader from "../components/PageHeader";
import "../styles/global.scss";
import Footer from "../components/Footer";
import DatoBlock, { DatoBlockProps } from "../components/dato/DatoBlock";
//import * as styles from "./DatoPageTemplate.module.scss"

export type DatoPageTemplateProps = PageProps<{
  page: {
    locale: string;
    title: string;
    /** Yksi pakollinen hero */
    hero: DatoBlockProps[];
    seoMetaTags?: Record<string, any>;
    content: DatoBlockProps[];
  };
  /** Site metadata */
  site: {
    siteMetadata: Record<string, any>;
  };
}> & {
  pageContext: { id: string; originalId: string; locale: string };
};

const DatoPageTemplate: React.FC<DatoPageTemplateProps> = (props) => {
  //console.log("Rendering DatoPageTemplate", props);

  const { data } = props;
  if (!data) {
    console.error("No data for gatsby-template DatoPageTemplate");
    return null;
  }

  const { page, site } = data;
  if (!page) {
    console.error("No page at", data);
    return null;
  }

  return (
    <>
      <HelmetDatoCms
        seo={page.seoMetaTags}
        titleTemplate={`%s | ${site.siteMetadata.title}`}
        defaultTitle={site.siteMetadata.title}
        htmlAttributes={{
          lang: page.locale,
        }}
      />
      <PageHeader context={props.pageContext} />
      <main>
        <DatoBlock {...page.hero[0]} />

        {page.content.map((module: DatoBlockProps, i: number) => {
          return <DatoBlock key={i} {...module} locale={page.locale} />;
        })}
      </main>
      <Footer context={props.pageContext} />
    </>
  );
};

export default DatoPageTemplate;

export const query = graphql`
  query DatoPageTemplateQuery($id: String!) {
    page: datoCmsPage(id: { eq: $id }) {
      seoMetaTags {
        tags
      }
      locale
      title
      hero {
        __typename
        ...DatoHero
        ...DatoSimpleHeading
      }
      content {
        __typename
        ...DatoTextBlock
        ...DatoImagesAndText
        ...DatoVideoEmbed
        ...DatoMediaBlock
        ...DatoAccordionList
        ...DatoIconList
        ...DatoContact
        ...DatoCookieDeclaration
      }
    }
    site {
      siteMetadata {
        title
      }
    }
  }
`;
