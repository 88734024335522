import { graphql, Link, useStaticQuery } from "gatsby";
import React from "react";
//import useRoutePath from "../../../hooks/useRoutePath";
//import { RouteTree, useRouteTree } from "../../../hooks/useRouteTree";
import {
  IoIosArrowDown,
  IoIosArrowUp,
  IoMdClose,
  IoMdMenu,
} from "react-icons/io";
import usePagePath from "../hooks/usePagePath";
import * as styles from "./MainNav.module.scss";

export type NavLinkNode = {
  localPath?: string;
  urlParams?: string;
  externalUrl?: string;
  label: string;
  position: number;
  nodes?: NavLinkNode[];
  hidden?: boolean;
};

export type NavLinkProps = NavLinkNode & { level: number };
const NavLink: React.FC<NavLinkProps> = (props) => {
  const path = props.localPath; // useRoutePath(props.routeId)
  const hasSubnav = !!props.nodes?.length;
  const [isOpen, setOpen] = React.useState(false);
  const thisItem = React.useRef() as React.RefObject<HTMLLIElement>;

  const onOpenClick = (/*e: React.MouseEvent*/): void => {
    // timeout, jotta avattava navigaatio ei heti sulkeudu windowClickHandlerin kautta
    const val = !isOpen;
    setTimeout(() => {
      setOpen(val);
    }, 0);
  };

  React.useEffect(() => {
    function windowClickHandler(e: MouseEvent): void {
      if (isOpen) {
        // find if clicked inside current nav tree, else close navigation
        let navClick = false;
        let cur = e.target as HTMLElement;
        while (cur) {
          //console.log(cur);
          if (cur === thisItem.current) {
            navClick = true;
            break;
          }
          cur = cur.parentElement as HTMLElement;
        }

        if (!navClick) setOpen(false);
      }
    }
    function windowKeyboardHandler(e: KeyboardEvent) {
      if (isOpen) {
        if (e.key === "Escape") {
          setOpen(false);
        }
      }
    }

    window.addEventListener("keydown", windowKeyboardHandler);
    window.addEventListener("click", windowClickHandler);

    return (): void => {
      window.removeEventListener("keydown", windowKeyboardHandler);
      window.removeEventListener("click", windowClickHandler);
    };
  }, [thisItem, isOpen]);

  if (props.hidden) {
    return null;
  }
  return (
    <li
      ref={thisItem}
      className={`${styles.navItem}${isOpen ? " " + styles.open : ""}`}
    >
      <div className={styles.linkRow}>
        {path ? (
          // Sisäinen linkki
          <Link to={path + (props.urlParams || "")}>{props.label}</Link>
        ) : (
          // Ulkoinen linkki
          <a href={props.externalUrl} target="_blank" rel="nofollow noopener">
            {props.label}
          </a>
        )}

        {hasSubnav && (
          <button
            className={styles.subnavToggle}
            onClick={onOpenClick}
            title={isOpen ? "Close" : "Open"}
          >
            {isOpen ? <IoIosArrowUp /> : <IoIosArrowDown />}
          </button>
        )}
      </div>

      {hasSubnav && (
        <ul
          className={`${styles.subnav} ${styles["subnav" + props.level]} ${
            props.nodes!.length > 3 && styles.subnavColumns
          }`}
        >
          {<NavLinks nodes={props.nodes} level={props.level + 1} />}
        </ul>
      )}
    </li>
  );
};

export type NavLinksProps = {
  nodes?: NavLinkNode[];
  level: number;
  hidden?: boolean;
};
const NavLinks: React.FC<NavLinksProps> = ({ nodes, level }) => {
  // console.debug("Rendering NavLinks", basePath, nodes)
  if (!nodes) {
    return null;
  }

  // linkit samaan järjestykseen kuin datossa ja poistetaan hidden elementit
  const orderedNodes = [...nodes]
    //.filter((itm) => !itm.hidden)
    .sort((a, b) => a.position - b.position);

  return (
    <>
      {orderedNodes.map(
        (node, i) => node && <NavLink key={i} {...node} level={level} />
      )}
    </>
  );
};
export type DatoMenuItem = {
  urlParams?: string;
  locale: string;
  label: string;
  id: string;
  position: number;
  page?: {
    id: string;
    originalId: string;
    locale: string;
  };
  externalUrl?: string;
  treeChildren: Array<DatoMenuItem>;
};
export type MainNavProps = { context: { locale: string } };
const MainNav: React.FC<MainNavProps> = (props) => {
  //console.log('Rendering MainNav', props)

  /** Päävalikko Datossa */
  const data = useStaticQuery(query).allDatoCmsMenuItem.nodes as DatoMenuItem[];

  const thisItem = React.useRef() as React.RefObject<HTMLElement>;
  const [isOpen, setOpen] = React.useState(false);

  React.useEffect(() => {
    function windowClickHandler(e: MouseEvent): void {
      // suljetaan navi jos klikattu sen ulkpouolelle
      if (isOpen) {
        let navClick = false;
        let cur = e.target as HTMLElement;
        while (cur) {
          if (cur === thisItem.current) {
            navClick = true;
            break;
          }
          cur = cur.parentElement as HTMLElement;
        }
        if (!navClick) setOpen(false);
      }
    }

    window.addEventListener("click", windowClickHandler);
    return (): void => {
      window.removeEventListener("click", windowClickHandler);
    };
  }, [isOpen, thisItem]);

  const clickHandler = (e: React.MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    setOpen(!isOpen);
  };

  function mapNodes(tree: DatoMenuItem["treeChildren"]) {
    return tree
      ?.map((node) => {
        /** Valikkopuun määritellyt lapsinodet */
        const childNodes: NavLinkNode[] = mapNodes(node.treeChildren) || [];
        const pagePath = node.page && usePagePath(node.page.id);

        // ei kohdetta
        if (!pagePath && !node.externalUrl) return null;

        return {
          label: node.label,
          localPath: pagePath,
          urlParams: node.urlParams,
          externalUrl: node.externalUrl,
          position: node.position,
          nodes: childNodes,
        };
      })
      .filter(Boolean) as NavLinkNode[];
  }

  /** Localen mukana valittu menu, ja sen lokalisoidut lapset */
  const menu = data.find((menu) => menu.locale === props.context.locale);

  if (!menu) return null;

  const nodes = mapNodes(menu.treeChildren);

  return (
    <nav
      className={`${styles.container} ${isOpen ? styles.open : styles.closed}`}
      ref={thisItem}
    >
      <button
        onClick={clickHandler}
        className={styles.menuToggle}
        aria-live="polite"
        title={isOpen ? "Close" : "Open"}
      >
        {isOpen ? <IoMdClose /> : <IoMdMenu />}
      </button>
      <ul className={styles.topnav}>
        <NavLinks nodes={nodes} level={1} hidden={false} />
      </ul>
    </nav>
  );
};

export default MainNav;

const query = graphql`
  fragment MenuItem on DatoCmsMenuItem {
    urlParams
    locale
    label
    id
    position
    externalUrl
    page {
      id
    }
  }
  query MainNav {
    # Päävalikot
    allDatoCmsMenuItem(filter: { label: { eq: "Päävalikko" } }) {
      nodes {
        ...MenuItem
        treeChildren {
          ...MenuItem
          treeChildren {
            ...MenuItem
            treeChildren {
              ...MenuItem
            }
          }
        }
      }
    }
  }
`;
