import React from 'react'
import * as styles from './Button.module.scss'

export type ButtonProps = React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> & {
  blue?: boolean
  selected?: boolean
}

const Button: React.FC<ButtonProps> = ({ blue, selected, ...props }) => {
  //console.log('Rendering Button', props)
  return (
    <button
      className={[props.className, styles.button, blue && styles.blue, selected && styles.selected]
        .filter(Boolean)
        .join(' ')}
      {...props}
    />
  )
}

export default Button
