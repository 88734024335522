import { graphql } from "gatsby";
import React from "react";
import { StructuredTextDocument } from "react-datocms";
import DatoStructuredTextBlock, { isEmpty } from "../DatoStructuredTextBlock";
import { slugify } from "../../../utils";
import SubHeading from "../../SubHeading";
//import * as styles from "./DatoTextBlock.module.scss"

export type DatoTextBlockProps = {
  title?: string;
  content?: StructuredTextDocument;
};

const DatoTextBlock: React.FC<DatoTextBlockProps> = (props) => {
  //console.log("Rendering DatoTextBlock", props);
  const noContent = isEmpty(props.content);
  return (
    <div className="layout-narrow" id={slugify(props.title)}>
      {props.title &&
        (noContent ? (
          <SubHeading>{props.title}</SubHeading>
        ) : (
          <h2>{props.title}</h2>
        ))}
      <DatoStructuredTextBlock data={props.content} shiftHeadings={2} />
    </div>
  );
};

export default DatoTextBlock;

export const query = graphql`
  fragment DatoTextBlock on DatoCmsTextBlock {
    id: originalId # Korjaus daton bugiin, jos käytetään structured textissä
    title
    content {
      value
      blocks {
        __typename
        ...DatoMediaBlock
      }
      links {
        __typename
        ... on DatoCmsPage {
          id: originalId
          targetId: id # linkin kohde
          label: title # button label
        }
      }
    }
  }
`;
