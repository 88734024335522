import { graphql } from "gatsby";
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image";
import { StructuredTextDocument } from "react-datocms";
import React from "react";
import DatoStructuredTextBlock from "../DatoStructuredTextBlock";
import { BsTelephone } from "react-icons/bs";
import { MdOutlineEmail } from "react-icons/md";
import * as styles from "./DatoContact.module.scss";
import { slugify } from "../../../utils";

export type DatoPerson = {
  name: string;
  title?: string;
  eMail?: string;
  phone?: string;
  description?: StructuredTextDocument;
  image?: {
    gatsbyImageData: IGatsbyImageData;
    alt: string;
  };
};

export type DatoContactProps = {
  title?: string;
  persons: DatoPerson[];
};

const DatoContact: React.FC<DatoContactProps> = (props) => {
  //console.log("Rendering DatoContact", props);
  return (
    <div className={styles.container} id={slugify(props.title)}>
      <div className="layout-normal">
        {props.title && <h2>{props.title}</h2>}
        {props.persons?.map((prsn, i) => {
          return (
            <div key={i} className={styles.contact}>
              {prsn.image && (
                <GatsbyImage
                  objectFit="contain"
                  objectPosition="left"
                  className={styles.image}
                  image={prsn.image.gatsbyImageData}
                  alt={prsn.image.alt || ""}
                />
              )}
              <div className={styles.info}>
                <h3>{prsn.name}</h3>
                <p>{prsn.title}</p>
                <a href={"tel:" + prsn.phone}>
                  <BsTelephone />
                  {prsn.phone}
                </a>
                <a href={"mailto:" + prsn.eMail}>
                  <MdOutlineEmail />
                  {prsn.eMail}
                </a>
              </div>
              <div className={styles.description}>
                <DatoStructuredTextBlock data={prsn.description} />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default DatoContact;

export const query = graphql`
  fragment DatoContact on DatoCmsContact {
    id: originalId # Korjaus daton bugiin, jos käytetään structured textissä
    title
    persons {
      title
      name
      eMail
      phone
      description {
        value
      }
      image {
        alt
        # gatsbyImageData(imgixParams: { w: "800", h: "500", fit: "crop" })
        gatsbyImageData(height: 250)
      }
    }
  }
`;
