import { graphql } from "gatsby";
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image";
import React from "react";
//import * as styles from "./DatoMediaBlock.module.scss"

export type DatoMediaBlockProps = {
  caption?: string;
  media: {
    gatsbyImageData: IGatsbyImageData;
    alt?: string;
  };
};

const DatoMediaBlock: React.FC<DatoMediaBlockProps> = (props) => {
  //console.log('Rendering DatoMediaBlock', props)
  return (
    <figure className="layout-normal">
      {props.media && (
        <GatsbyImage
          image={props.media.gatsbyImageData}
          alt={props.media.alt || ""}
        />
      )}
      <figcaption>{props.caption}</figcaption>
    </figure>
  );
};

export default DatoMediaBlock;

export const query = graphql`
  fragment DatoMediaBlock on DatoCmsMediaBlock {
    id: originalId # Korjaus daton bugiin, jos käytetään structured textissä
    caption
    media {
      alt
      gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
    }
  }
`;
