/**
 * Siirtää structured text datan otsikkotasoja amount määrän.
 */
export const shiftHeadings = (data: any, amount: number) => {
  if (data.value?.document) {
    // Kloonataan root, jotta shiftaus ei muuta samaa objektia joka rendauksella.
    data = JSON.parse(JSON.stringify(data));
    data.value.document = shiftHeadings(data.value.document, amount);
  } else if (data.children) {
    data.children.forEach((child: any) => {
      if (child.type == "heading") {
        child.level = Math.min(child.level + amount, 6);
      }
      child = shiftHeadings(child, amount);
    });
  }
  return data;
};
