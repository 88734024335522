//import { Link } from "gatsby"
import { graphql, navigate, useStaticQuery } from "gatsby";
import React from "react";
import usePagePath from "../hooks/usePagePath";
import * as styles from "./LanguageSelect.module.scss";
export type LanguageSelectProps = {
  context: { id: string; originalId: string; locale: string };
};
const LanguageSelect: React.FC<LanguageSelectProps> = (props) => {
  const locales: string[] = useStaticQuery(graphql`
    query LocalesQuery {
      datoCmsSite {
        locales
      }
    }
  `).datoCmsSite.locales;

  const changeHandler = (e: React.ChangeEvent<HTMLSelectElement>) => {
    navigate(e.currentTarget.value);
  };

  if (locales.length <= 1) {
    return null;
  }

  return (
    <select
      className={styles.select}
      onChange={changeHandler}
      defaultValue={usePagePath(props.context.id)}
    >
      {locales.map((locale) => {
        return (
          <option
            key={locale}
            value={
              usePagePath(props.context.originalId, locale) ||
              (locale === "fi" ? "/" : "/" + locale) // etusivulle, jos käännöstä nykyisestä sivusta ei löydy
            }
          >
            {locale.toUpperCase()}
          </option>
        );
      })}
    </select>
  );
};

export default LanguageSelect;
