import { graphql, Link, useStaticQuery } from "gatsby";
import React from "react";
import usePagePath from "../hooks/usePagePath";
import * as styles from "./Footer.module.scss";
import { DatoMenuItem } from "./MainNav";

const FooterLink = (props: DatoMenuItem) => {
  const path = props.page && usePagePath(props.page.id);
  return (
    <li>
      {path ? (
        // Sisäinen linkki
        <Link to={path + (props.urlParams || "")}>{props.label}</Link>
      ) : (
        // Ulkoinen linkki
        <a href={props.externalUrl} target="_blank" rel="nofollow noopener">
          {props.label}
        </a>
      )}

      {!!props.treeChildren?.length && <LinkList nodes={props.treeChildren} />}
    </li>
  );
};

const LinkList = (props: { nodes: DatoMenuItem[] }) => {
  // linkit samaan järjestykseen kuin datossa ja poistetaan hidden elementit
  const orderedNodes = [...props.nodes]
    //.filter((itm) => !itm.hidden)
    .sort((a, b) => a.position - b.position);

  if (!orderedNodes.length) return null;

  return (
    <ul>
      {orderedNodes.map((item) => {
        return <FooterLink key={item.id} {...item} />;
      })}
    </ul>
  );
};

export type FooterProps = { context: { locale: string } };

const Footer: React.FC<FooterProps> = (props) => {
  //console.log('Rendering Footer', props)

  /** Footer valikko Datossa */
  const data = useStaticQuery(query).allDatoCmsMenuItem.nodes as DatoMenuItem[];

  const menu = data.find((menu) => menu.locale === props.context.locale);

  return (
    <footer className={styles.container}>
      <div className="layout-normal">
        <div className={styles.content}>
          <nav className={styles.nav}>
            {menu && <LinkList nodes={menu.treeChildren} />}
          </nav>

          <div className={styles.contact}>
            <h3>PRGS Finland Oy</h3>
            <p>
              Pargas rekreation,
              <br />
              gemenskap och sport
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

const query = graphql`
  # fragment MenuItem on määritetty MainNav.tsx :ssä
  query FooterNav {
    allDatoCmsMenuItem(filter: { label: { eq: "Footer valikko" } }) {
      nodes {
        ...MenuItem
        treeChildren {
          ...MenuItem
          treeChildren {
            ...MenuItem
            treeChildren {
              ...MenuItem
            }
          }
        }
      }
    }
  }
`;
