/**
 * Make valid slug from any string
 * @param title Text
 * @param id Unique identifier, e.g. originalId to stringify and append to end of slug
 * @returns
 */
export function slugify(title?: string, id?: number) {
  if (!title && !id) return;

  const separator = "-";
  const title_str = title
    ?.toString()
    .normalize("NFD") // split an accented letter in the base letter and the acent
    .replace(/[\u0300-\u036f]/g, "") // remove all previously split accents
    .toLowerCase()
    .trim()
    .replace(/[^a-z0-9 ]/g, "") // remove all chars not letters, numbers and spaces (to be replaced)
    .replace(/\s+/g, separator);

  const id_str = id && parseInt(id.toString())?.toString(32);

  const slug = [title_str, id_str].filter(Boolean).join(separator);

  return slug;
}
