import { graphql } from "gatsby";
import React from "react";
//import * as styles from "./DatoSimpleHeading.module.scss"

export type DatoSimpleHeadingProps = { title: string };

const DatoSimpleHeading: React.FC<DatoSimpleHeadingProps> = (props) => {
  //console.log('Rendering DatoSimpleHeading', props)
  return (
    <div className="layout-normal" style={{ textAlign: "center" }}>
      <h1>{props.title}</h1>
    </div>
  );
};

export default DatoSimpleHeading;

export const query = graphql`
  fragment DatoSimpleHeading on DatoCmsSimpleHeading {
    id: originalId # Korjaus daton bugiin, jos käytetään structured textissä
    title
  }
`;
