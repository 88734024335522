import React from "react";
//import * as styles from "./SubHeading.module.scss"

export type SubHeadingProps = {} & React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLHeadingElement>,
  HTMLHeadingElement
>;

const SubHeading: React.FC<SubHeadingProps> = (props) => {
  //console.log('Rendering SubHeading', props)
  return (
    <h2 className="layout-narrow" style={{ textAlign: "center" }}>
      {props.children}
    </h2>
  );
};

export default SubHeading;
