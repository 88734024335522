import { graphql } from "gatsby";
import React from "react";
import { slugify } from "../../../utils";
import SubHeading from "../../SubHeading";
import VideoEmbed, { VideoEmbedProps } from "../../VideoEmbed";
import * as styles from "./DatoVideoEmbed.module.scss";

export type DatoVideoEmbedProps = {
  video: VideoEmbedProps;
  title?: string;
};

const DatoVideoEmbed: React.FC<DatoVideoEmbedProps> = (props) => {
  //console.log('Rendering DatoVideoEmbed', props)
  return (
    <div id={slugify(props.title)} className={styles.container}>
      {props.title && <SubHeading>{props.title}</SubHeading>}
      <VideoEmbed {...props.video} />
    </div>
  );
};

export default DatoVideoEmbed;

export const query = graphql`
  fragment DatoVideoEmbed on DatoCmsVideoEmbed {
    id: originalId # Korjaus daton bugiin, jos käytetään structured textissä
    title
    video {
      url
      title
      provider
      providerUid
      thumbnailUrl
      width
      height
    }
  }
`;
