import React from "react";
import DatoAccordionList, {
  DatoAccordionListProps,
} from "./blocks/DatoAccordionList";
import DatoContact, { DatoContactProps } from "./blocks/DatoContact";
import DatoCookieDeclaration, {
  DatoCookieDeclarationProps,
} from "./blocks/DatoCookieDeclaration";
import DatoHero, { DatoHeroProps } from "./blocks/DatoHero";
import DatoIconList, { DatoIconListProps } from "./blocks/DatoIconList";
import DatoImagesAndText, {
  DatoImagesAndTextProps,
} from "./blocks/DatoImagesAndText";
import DatoMediaBlock, { DatoMediaBlockProps } from "./blocks/DatoMediaBlock";
import DatoSimpleHeading, {
  DatoSimpleHeadingProps,
} from "./blocks/DatoSimpleHeading";
import DatoTextBlock, { DatoTextBlockProps } from "./blocks/DatoTextBlock";
import DatoVideoEmbed, { DatoVideoEmbedProps } from "./blocks/DatoVideoEmbed";

export type DatoBlockProps = { __typename: string } & {
  [prop: string]: unknown;
};

/**
 * Mappaa Datosta block-tyyppiset sisällöt React komponetteihin.
 */
const DatoBlock = (props: DatoBlockProps) => {
  // console.debug("Rendering DatoBlock", props)
  const { __typename, ...rest } = props;
  switch (__typename) {
    case "DatoCmsTextBlock":
      return <DatoTextBlock {...(rest as DatoTextBlockProps)} />;

    case "DatoCmsImagesAndText":
      return <DatoImagesAndText {...(rest as DatoImagesAndTextProps)} />;

    case "DatoCmsVideoEmbed":
      return <DatoVideoEmbed {...(rest as DatoVideoEmbedProps)} />;

    case "DatoCmsMediaBlock":
      return <DatoMediaBlock {...(rest as DatoMediaBlockProps)} />;

    case "DatoCmsAccordionList":
      return <DatoAccordionList {...(rest as DatoAccordionListProps)} />;

    case "DatoCmsIconList":
      return <DatoIconList {...(rest as DatoIconListProps)} />;

    case "DatoCmsContact":
      return <DatoContact {...(rest as DatoContactProps)} />;

    case "DatoCmsHero":
      return <DatoHero {...(rest as DatoHeroProps)} />;

    case "DatoCmsSimpleHeading":
      return <DatoSimpleHeading {...(rest as DatoSimpleHeadingProps)} />;

    case "DatoCmsCookieDeclaration":
      return (
        <DatoCookieDeclaration {...(rest as DatoCookieDeclarationProps)} />
      );

    default:
      console.warn("Unmapped DatoBlock:", __typename);
      return null; //<div>&lt;Unmapped DatoBlock: {__typename}&gt;</div>;
  }
};

export default DatoBlock;
