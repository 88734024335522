import React from 'react'
import * as styles from './Accordion.module.scss'

export type AccordionProps = {
  title: string
  category?: string

  /** Initially open */
  open?: boolean

  /** Title field props */
  titleProps?: {}
}

export const AccordionStateless: React.FC<AccordionProps & { toggle?: () => void }> = (props) => {
  return (
    <>
      <div className={`${styles.container} ${!props.open ? styles.closed : ''}`}>
        <div className={styles.titleBar}>
          <div className={styles.titleBarText} onClick={props.toggle} role="button">
            {props.category && <div className={styles.category}>{props.category}</div>}
            <h3 className={styles.title} {...props.titleProps}>
              {props.title}
            </h3>
          </div>
          {!!props.toggle && (
            <button className={styles.toggleButton} onClick={props.toggle} title={props.title.toString()}></button>
          )}
        </div>

        <div className={styles.content}>{props.children}</div>
      </div>
    </>
  )
}

const Accordion: React.FC<AccordionProps> = (props) => {
  const initiallyOpen = typeof props.open !== 'undefined' && props.open
  const [open, setOpen] = React.useState<boolean>(initiallyOpen)
  const toggle = () => {
    setOpen(!open)
  }

  return <AccordionStateless {...{ ...props, toggle, open }} />
}

export default Accordion
