import React, { Children } from "react";
import * as styles from "./Carousel.module.scss";
import { IoMdArrowBack, IoMdArrowForward } from "react-icons/io";

export type CarouselProps = {
  slideClass?: string;
  containerClass?: string;
  hideDots?: boolean;
  hideControls?: boolean;
};

const Carousel: React.FC<CarouselProps> = (props) => {
  //console.log('Rendering Carousel', props)

  const list = React.useRef<HTMLUListElement>(null);
  const listContainer = React.useRef<HTMLDivElement>(null);

  const [page, setPage] = React.useState(0);
  const [pageCount, setPageCount] = React.useState(0);

  /*
  
  Päivitetään sivujen määrä
  
  */
  React.useEffect(() => {
    const containerWidth = listContainer.current?.getBoundingClientRect().width;
    let contentWidth;

    const slideCount = list.current?.children?.length;

    //console.log('slides', slideCount)

    if (slideCount) {
      contentWidth =
        list.current?.children[slideCount - 1].getBoundingClientRect().right -
        10 -
        list.current?.children[0].getBoundingClientRect().left;
    }

    if (containerWidth && contentWidth) {
      setPageCount(Math.floor(contentWidth / containerWidth));
    }
    setPage(0);
  }, [Children.count(props.children), listContainer.current?.offsetWidth]);

  /*
  
  Raahaus
  
  */
  React.useEffect(() => {
    const slideContainer = list.current;

    if (!slideContainer) return;

    const drag = true;
    const pointer = { x: 0, y: 0 };
    const startpos = { x: 0, y: 0 };
    let dragging = false;
    let validDrag = false;
    const validDragDistance = 50;
    let startLeftStyle: string;

    const onPointerDownOrTouchStart = (e: PointerEvent | TouchEvent) => {
      //console.log('pointer down')
      if (drag) {
        dragging = true;
        validDrag = false;

        pointer.x = startpos.x =
          (e as PointerEvent).pageX || (e as TouchEvent).touches[0].pageX;

        slideContainer.style.transition = "none";

        startLeftStyle = slideContainer.style.left;

        window.addEventListener("pointermove", onPointerMove);
        window.addEventListener("pointerup", onPointerUp);

        updateDrag();
      }
    };
    const onPointerMove = (e: PointerEvent) => {
      //console.log('pointer move')
      if (dragging) {
        e.preventDefault();
        pointer.x = e.pageX; // || e.touches[0].pageX
      }
    };

    const updateDrag = () => {
      if (dragging) {
        requestAnimationFrame(updateDrag);

        if (!validDrag) {
          const dif = Math.abs(pointer.x - startpos.x);

          if (dif > validDragDistance) {
            validDrag = true;
            // estetään klikkaukset, kun raahataan
            slideContainer.style.pointerEvents = "none";
          }
        }

        slideContainer.style.left =
          "calc(" + startLeftStyle + " + " + (pointer.x - startpos.x) + "px)";
      }
    };

    const onPointerUp = (/*e: PointerEvent*/) => {
      //console.log('pointer up')

      if (dragging) {
        //calculatePages();
        dragging = false;

        if (validDrag) {
          const dif = pointer.x - startpos.x;

          if (dif > validDragDistance && page > 0) {
            prev();
          } else if (dif < -validDragDistance && page < pageCount) {
            next();
          } else {
            slideContainer.style.left = startLeftStyle;
          }
        } else {
          slideContainer.style.left = startLeftStyle;
        }

        slideContainer.style.pointerEvents = "";
        slideContainer.style.transition = "";
        window.removeEventListener("pointermove", onPointerMove);
        window.removeEventListener("pointerup", onPointerUp);
      }
    };

    slideContainer.addEventListener("pointerdown", onPointerDownOrTouchStart);

    return () => {
      list.current?.removeEventListener(
        "pointerdown",
        onPointerDownOrTouchStart
      );
      window.removeEventListener("pointermove", onPointerMove);
      window.removeEventListener("pointerup", onPointerUp);
    };
  }, [list.current, page, pageCount]);

  /*

  End of raahaus

  */

  const prev = () => setPage(Math.max(page - 1, 0));

  const next = () => setPage(Math.min(page + 1, pageCount));

  //console.log('page', page + '/' + pageCount)

  return (
    <div className={styles.container}>
      <div ref={listContainer} className={styles.listContainer}>
        <ul
          ref={list}
          className={[styles.list, props.containerClass]
            .filter(Boolean)
            .join(" ")}
          style={{ left: `${-page * 100}%` }}
        >
          {Children.map(props.children, (item, i) => {
            return (
              <li
                key={i}
                className={[styles.slide, props.slideClass]
                  .filter(Boolean)
                  .join(" ")}
              >
                {item}
              </li>
            );
          })}
        </ul>
      </div>

      {!props.hideControls && (
        <div className={styles.controls}>
          <button onClick={prev} title="Previous" disabled={page == 0}>
            <IoMdArrowBack />
          </button>
          <button onClick={next} title="Next" disabled={page == pageCount}>
            <IoMdArrowForward />
          </button>
        </div>
      )}

      {!props.hideDots && (
        <nav className={styles.dots}>
          {Array.from(Array(pageCount + 1).keys()).map((i) => (
            <button
              key={i}
              className={[styles.dot, page == i && styles.active]
                .filter(Boolean)
                .join(" ")}
              title={"Sivu " + (i + 1)}
              onClick={() => setPage(i)}
            ></button>
          ))}
        </nav>
      )}
    </div>
  );
};

export default Carousel;
